import { Component } from '@angular/core';
import { Template } from '../../../shared/models/super-admin.model'
import { SuperAdminService } from '../../../shared/services/super-admin.service';
import { GenericService } from '../../../shared/services/generic.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'app-super-admin-template',
  standalone: true,
  imports: [FormsModule, RouterModule],
  templateUrl: './super-admin-template.component.html',
  styleUrl: './super-admin-template.component.less'
})
export class SuperAdminTemplateComponent {
  public version: number = 0
  public templates: Template[] = [];
  public excludeGidInput: string = '';
  public excludeGid: string[] = [];
  public selectedFormat: number = 1;
  public url: string = '';
  public responseMessage: string = '';

  constructor (
    public superAdminService: SuperAdminService,
    public genericService: GenericService
  ) {}

  ngOnInit(): void {
    this.getVersion()
    this.getTemplates()
  }

  getVersion(): void {
    this.superAdminService.getTemplateVersion().subscribe({
      next: (response) => {
        this.version = (response.body || 0)
      }
    })
  }

  getTemplates(): void {
       this.superAdminService.getReportTemplates().subscribe({
      next: (response) => {
        this.templates = (response.body || 0)
      }
    })
  }

  updateExcludeGid(): void {
    this.excludeGid = this.excludeGidInput
      .split(',')
      .map((gid) => gid.trim())
      .filter((gid) => gid !== ''); // 빈 문자열 제거
  }

  // 테스트 버튼 동작
  testCreateTemplates(): void {
    const endpoint = 'super-admin/template-spreadsheet/test';
    const payload = this.generatePayload();
    this.superAdminService.sendTemplateRequest(endpoint, payload).subscribe({
      next: (response) => {
        this.responseMessage = JSON.stringify(response.body?.detail, null, 2)
      }
    });
  }

  // 생성 버튼 동작
  createTemplates(): void {
    const endpoint = 'super-admin/template-spreadsheet';
    const payload = this.generatePayload();
    this.superAdminService.sendTemplateRequest(endpoint, payload).subscribe({
      next: (response) => {
        this.responseMessage = JSON.stringify(response.body?.detail, null, 2);
        this.getVersion();
      }
    });
  }

  // 내역 지우기 버튼 동작
  deleteresponseMessage(): void {
    this.responseMessage = '';
  }

  // Payload 생성
  private generatePayload(): any {
    return {
      platform: 1, // 고정값
      raw_data_format_type: this.selectedFormat, // 선택된 로우데이터 포맷 타입
      url: this.url, // 입력된 URL
      exclude_gids: this.excludeGid, // 제외 Gid 배열
    };
  }

  deleteTemplate(report_no: number): void{
    this.superAdminService.deleteReport(report_no).subscribe({
      next: () => {
        this.templates = this.templates.filter(template => template.report_no !== report_no);
      }
    })
  }

  updateReportNo(): void {
    this.superAdminService.updateReportNo().subscribe({
      next: (response) => {
        if (response.body?.detail) {
          this.getTemplates();
          this.responseMessage = '업데이트 완료! 검사를 진행해주세요.'
        }
      }
    })
  }

  validateReportNo(): void {
    this.superAdminService.validateReportNo().subscribe({
      next: (response) => {
        const validation = response.body?.detail?.validation;
        const report_no = response.body?.detail?.report_no;

        // validation 값에 따라 메시지 설정
        if (validation) {
          this.responseMessage = `리포트 번호가 모두 유효합니다.`;
        } else {
          this.responseMessage = `리포트 번호 ${report_no}가 존재하지 않습니다. 1번부터 연속해야합니다. 리포트 번호 최신화 버튼을 눌러주세요`;
        }
      },
      error: (err) => {
        this.responseMessage = `검사 실패: ${err.message}`;
      },
    });
  }

  getNotification():void {
    this.responseMessage = `
    1. test 가 올바른 데이터를 추출하는지 확인하고 실행해주세요.
    템플릿들이 들어있는 스프레드시트에서 데이터를 가져와 템플릿을 데이터베이스에 저장합니다.
    템플릿 제목은 로우데이터와 템플릿 이름이 "-"로 구분되어 있어야 합니다.(ex. "NSA_T-파워링크 캠페인별 키워드 그래프")
    첫번째 "-"는 제외하고 두번째 "-"부터 한글이름으로 인식합니다.

    2. 삭제나 만들기 이후 검사를 통과하지 않으면 오류가 발생합니다.

    3. 템플릿 넘버는 1부터 연속된 숫자여야 합니다. 중간에 비어있으면 오류가 발생합니다.
    
    `
  }

}