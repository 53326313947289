/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare const gapi: any;
declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class GooglePickerService {
  private oauthToken: string | null = null;

  constructor() {}

  initializePicker(): Promise<void> {
    return new Promise((resolve, reject) => {
      gapi.load('client:auth2', () => {
        gapi.client
          .init({
            apiKey: environment.apiKey,
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
          })
          .then(() => {
            gapi.load('picker', () => {
              console.log('Google Picker API loaded');
              resolve();
            });
          })
          .catch((error: any) => {
            console.error('Error during gapi.client.init:', error);
            reject(error);
          });
      });
    });
  }

  authenticate(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!google || !google.accounts || !google.accounts.oauth2) {
        console.error('Google Identity Services (GIS) not loaded');
        return reject('GIS library not loaded.');
      }

      const client = google.accounts.oauth2.initTokenClient({
        client_id: environment.clientId,
        scope: 'https://www.googleapis.com/auth/drive.file',
        callback: (response: any) => {
          if (response.error) {
            console.error('Authorization failed:', response.error);
            reject(response.error);
          } else {
            this.oauthToken = response.access_token;
            resolve();
          }
        },
      });

      client.requestAccessToken();
    });
  }

  openPicker(callback: (data: any) => void, type: 'sheets' | 'directory'): void {
    if (!this.oauthToken) {
      this.initializePicker()
        .then(() => this.authenticate())
        .then(() => this.createPicker(callback, type))
        .catch((error: any) => {
          console.error('Error initializing Google Picker:', error);
        });
    } else {
      this.createPicker(callback, type);
    }
  }

  private createPicker(callback: (data: any) => void, type: 'sheets' | 'directory'): void {
    if (!google?.picker) {
      console.error('Google Picker is not loaded');
      return;
    }

    // View 설정
    let view: any;

    if (type === 'sheets') {
      // 스프레드시트를 선택하는 View
      view = new google.picker.View(google.picker.ViewId.SPREADSHEETS);
    } else {
      // 폴더를 선택하는 View
      const docsView = new google.picker.DocsView(google.picker.ViewId.FOLDERS);
      docsView.setSelectFolderEnabled(true); // 폴더 선택 활성화
      docsView.setMimeTypes('application/vnd.google-apps.folder'); // MIME 타입 필터링
      view = docsView;
    }

    // Picker 빌더 생성
    const picker = new google.picker.PickerBuilder().addView(view).setOAuthToken(this.oauthToken!).setDeveloperKey(environment.apiKey).setCallback(callback).build();

    // Picker 표시
    picker.setVisible(true);
  }
}
