import { Component } from '@angular/core';
import { SuperAdminService } from '../../shared/services/super-admin.service';
import { User } from '../../shared/models/super-admin.model';
import { GenericService } from '../../shared/services/generic.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'app-super-admin',
  standalone: true,
  imports: [FormsModule, RouterModule],
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.less']
})
export class SuperAdminComponent {
  public users: User[] = [];

  constructor(
    public superAdminService: SuperAdminService,
    public genericService: GenericService
  ) {}

  ngOnInit(): void {
  this.superAdminService.getAllUsers().subscribe({
    next: (response) => {
      this.users = (response.body?.detail || []).map((user: User) => ({
        ...user,
        sign_up_date: this.convertToKST(user.sign_up_date),
        first_raw_data_import_date: this.convertToKST(user.first_raw_data_import_date),
        last_raw_data_import_date: this.convertToKST(user.last_raw_data_import_date),
      }));
    },
    error: (err) => {
      console.error('API Error:', err);
    },
  });
}

  save(user: User): void {
    const updatedData: Partial<User> = {
        user_id: user.user_id,
        company_name: user.company_name,
        company_business_number: user.company_business_number,
        is_billing: user.is_billing,
        memo_one: user.memo_one,
        memo_two: user.memo_two,
        memo_three: user.memo_three,
    };

    this.superAdminService.patchUser(updatedData);
  }

  convertToKST(dateString: string): string {
    if (!dateString) return ''; // 유효하지 않은 날짜 처리
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    return date.toLocaleString('ko-KR', options); // 한국 시간대로 변환
  }

  toggleBilling(user: User): void {
    user.is_billing = !user.is_billing;

    // 상태를 즉시 서버로 업데이트하는 경우
    const updatedData: Partial<User> = { user_id: user.user_id, is_billing: user.is_billing };
    this.superAdminService.patchUser(updatedData);
  }
}
