<article id="start-google-drive">
  <section class="start-drive-header">
    <div class="start-drive-header-title-wrap">
      <div class="start-drive-header-stepper">
        <span class="start-drive-header-step" [ngClass]="{ active: !selectedConversion.value }">1</span>
        <span class="start-drive-header-bridge"></span>
        <span class="start-drive-header-step" [ngClass]="{ active: selectedConversion.value }">2</span>
      </div>
      <h1 class="start-drive-header-title" [@header]>
        {{ selectedConversion.value ? '데이터를 연동할 경로를 설정해 주세요.' : '전환값의 기준을 선택해 주세요.' }}
      </h1>
    </div>
    <img (click)="onCancel()" src="assets/icon/close.svg" alt="close icon" />
  </section>

  <section class="start-drive-conversion">
    <div [matMenuTriggerFor]="conversionDropdownOption" class="start-drive-conversion-select">
      <div class="start-drive-conversion-select-wrap" [ngClass]="{ selected: selectedConversion.value }">
        {{ selectedConversion.value ? selectedConversion.value : '전환값을 선택해 주세요.' }}
      </div>
      <img src="assets/icon/arrow_dropdown.svg" alt="dropdown arrow" class="platform-dropdown-select-arrow" />
    </div>
    <mat-menu #conversionDropdownOption="matMenu" class="start-drive-conversion-option">
      @for (conversion of conversionEntries; track $index) {
        <div mat-menu-item (click)="selectedConversion = conversion">{{ conversion.value }}</div>
      }
    </mat-menu>
  </section>

  @if (selectedConversion.value) {
    <section class="start-drive-main" [@content]>
      <div class="start-drive-main-root"><img src="assets/icon/google_drive.png" alt="google drive icon" /> <span>내 드라이브</span></div>
      <button (click)="onClickButton('directory')" class="start-drive-selected-directory">연동 위치 변경</button>
    </section>

    <section class="start-drive-footer">
      <button (click)="onClickButton('sheets')" class="start-drive-selected-sheets">기존에 사용하던 스프레드시트에 데이터 연동</button>
      <button (click)="onClickButton('save')" class="start-drive-selected-save">연동</button>
    </section>
  }
</article>
