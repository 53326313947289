import { Routes } from '@angular/router';
import { authGuard } from '../core/guards/auth.guard';
import { superadminGuard } from '../core/guards/super-admin.guard'
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AccountsComponent } from './accounts/accounts.component';
import { MyDataComponent } from './my-data/my-data.component';
import { AddDataComponent } from './add-data/add-data.component';
import { MobileComponent } from './mobile/mobile.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsComponent } from './terms/terms.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { SuperAdminTemplateComponent } from './super-admin/super-admin-template/super-admin-template.component';
import { SupportComponent } from './support/support.component';

export const featureRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'support', component: SupportComponent },
  { path: 'home', component: HomeComponent, canActivate: [authGuard] },
  { path: 'accounts', component: AccountsComponent, canActivate: [authGuard] },
  { path: 'my-data', component: MyDataComponent, canActivate: [authGuard] },
  { path: 'add-data', component: AddDataComponent, canActivate: [authGuard] },
  { path: 'mobile', component: MobileComponent, canActivate: [authGuard] },
  { path: 'super-admin', component: SuperAdminComponent, canActivate: [superadminGuard] },
  { path: 'super-admin/template', component: SuperAdminTemplateComponent, canActivate: [superadminGuard]},
  { path: '**', redirectTo: '' },
];
