import { Component } from '@angular/core';
import { GenericService } from './shared/services/generic.service';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  template: `
    <div id="im-report-body">
      <header id="im-report-gnb"><app-im-report-gnb></app-im-report-gnb></header>
      <section id="im-report-main"><router-outlet></router-outlet></section>
      <footer id="im-report-footer"><app-footer></app-footer></footer>
    </div>
  `,
  styleUrl: './app.component.less',
})
export class AppComponent {
  constructor(
    private genericService: GenericService,
    public userService: UserService,
  ) {}
  ngOnInit(): void {
    this.genericService.pushToDataLayer('page_view', { page_name: this.genericService.currentPath });

    this.genericService.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(() => this.genericService.pushToDataLayer('page_view', { page_name: this.genericService.currentPath }));
  }
}
