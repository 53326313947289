import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { ApiService } from '../../core/services/api.service';
import { User } from '../../shared/models/super-admin.model'

@Injectable({
    providedIn: 'root',
})
export class SuperAdminService {
    Users: User[] = [];
    isLogin: boolean = false;
    Version: number = 0;
    report_no_validation: boolean = false;
    false_report_no: number = 0;
    constructor(
        private apiService: ApiService,        
    ) {}

    getAllUsers() {
        const url = 'super-admin/users';
        return this.apiService.select(url).pipe(
            tap((response) => {
                this.Users = response.body.detail;
            }),
        );
    }

    patchUser(updatedUser: Partial<User>): void {
        const url = `super-admin/user`; // 사용자 ID를 기반으로 URL 구성
        this.apiService.patch(url, updatedUser).subscribe({
            next: () => {},
            error: (err) => {
                console.error('사용자 정보 업데이트 중 오류가 발생했습니다.', err);
            },
        });
    }

    getTemplateVersion() {
        const url = 'super-admin/version'
        return this.apiService.select(url).pipe(
            tap((response) => {
                this.Version = response.body;
            }),
        );
    }

    getReportTemplates() {
        const url = 'super-admin/templates'
        return this.apiService.select(url).pipe(
            tap((response) => {
                this.Version = response.body.detail;
            })
        )
    }

    sendTemplateRequest(endpoint: string, payload: any) {
        return this.apiService.create(endpoint, payload).pipe(
            tap({
                next: (response) => {
                    console.log('요청 성공:', response);
                },
                error: (err) => {
                    console.error('요청 실패:', err);
                },
            })
        );
    }

    deleteReport(report_no: number) {
        const url = 'super-admin/template/' + report_no
        return this.apiService.delete(url).pipe(
            tap({
                next: (response) => {}
            })
        )
    }

    updateReportNo() {
        const url = 'super-admin/report-no/update'
        return this.apiService.create(url).pipe(
            tap((response) => {}
        )
        )
    }

    validateReportNo() {
        const url = 'super-admin/check-valid/report-no'
        return this.apiService.select(url).pipe(
            tap((response) => {
                this.report_no_validation = response.body.detail.validation;
                this.false_report_no = response.body.detail.validation;
            })
        )
    }
}