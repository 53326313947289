<footer id="landing-footer">
  <section class="footer-sitemap">
    <h1 class="footer-product-logo"><img src="assets/icon/logo_black.svg" alt="im report logo" /></h1>
    <div class="footer-sitemap-wrap">
      <nav>
        <ul role="list">
          <p><strong>Manual</strong></p>
          <li><a href="https://saladlab.notion.site/811de288eaa04aa5a2dec1f429cd050d" target="_blank">Guide</a></li>
          <li routerLink="/support" style="cursor: pointer">Support</li>
          <!-- <p><strong>체험/문의</strong></p> -->
          <!-- <li><a href="" target="_blank">제안서</a></li> -->
          <!-- <li><a href="https://imreport.oopy.io/" target="_blank">메뉴얼</a></li> -->
          <!-- <li><a href="https://imreport.channel.io/" target="_blank">1:1문의</a></li> -->
        </ul>
        <ul role="list">
          <p><strong>IT 솔루션랩</strong></p>
          <li><a href="https://alph.kr/" target="_blank">알파리뷰</a></li>
          <li><a href="https://upsell.alph.kr/" target="_blank">알파업셀</a></li>
          <li><a href="https://store.cafe24.com/kr/apps/20261" target="_blank">알파푸시</a></li>
          <li><a href="https://a-review.co" target="_blank">알파리뷰 글로벌</a></li>
        </ul>
        <ul role="list">
          <p><strong>마케팅랩</strong></p>
          <li><a href="https://studiomx.co.kr/" target="_blank">스튜디오MX</a></li>
          <!-- <li><a href="http://ww25.trendis.me/?subid1=20240802-1507-355a-9fde-c85fb7e63342" target="_blank">패시네이션</a></li> -->
          <li><a href="/">아임리포트</a></li>
        </ul>
        <ul role="list">
          <p><strong>회사</strong></p>
          <li><a href="https://saladlab.co/" target="_blank">채용</a></li>
          <li><a href="https://blog.naver.com/alphareview" target="_blank">블로그</a></li>
        </ul>
        <ul role="list">
          <p><strong>Privacy & Terms</strong></p>

          <li routerLink="/privacy-policy" style="cursor: pointer; text-decoration: underline">Privacy policy</li>
          <li routerLink="/terms" style="cursor: pointer; text-decoration: underline">Terms</li>
        </ul>
      </nav>
      <aside>
        <p>
          <strong>Contact</strong>
          <!-- <strong><a href="https://imreport.channel.io/" target="_blank">간편문의</a></strong> -->
        </p>
        <a href="mailto:contact@imreport.io">{{ 'contact@imreport.io' }}</a>
        <!-- <p>02-553-1008</p>
        <p>운영시간 10:00 ~ 17:00 (평일)</p>
        <p>점심시간 12:20 ~ 13:20</p> -->
        <!-- <p>{{ 'info@alph.kr' }}</p> -->
      </aside>
    </div>
  </section>
  <section class="footer-company">
    <h1 class="footer-company-logo">
      <img src="assets/images/logo_company.png" alt="saladlab company logo" />
      <span class="split"></span>
      <strong>CEO 신동훈</strong><strong>CMO 김태완</strong>
    </h1>
    <div class="footer-company-wrap">
      <div class="footer-company-info">
        <p>249-88-00700<span class="split"></span>2017-서울강남-01406</p>
        <p>서울시 강남구 테헤란로 78길 14-11, 6층 8층</p>
      </div>
      <div class="footer-company-copyright">
        <p>Copyright (C) 샐러드랩 ALL RIGHT RESERVED</p>
        <p>소비자와 판매자 모두 행복한 커머스 시장을 만듭니다.</p>
      </div>
    </div>
  </section>
</footer>
