import { Platform } from './accounts.model';
export class RawDataFormat {
  platform: Platform = Platform.NAVER;
  naver_account_id: number = 0;
  type: RawDataFormatType = RawDataFormatType.NSA_CTD;
  template: string[] = [];
  folder_id?: string;
  folder_name?: string;
  spreadsheet_id?: string;
  spreadsheet_name?: string;
  conversion_type: string = '';
}

export interface MyRawData {
  raw_data: RawData;
  importing_status: RawDataImportStatus;
  is_first: boolean;
  update_status: boolean;
  update_status_reason: UpdateStatus;

  _hover: boolean;
}

export interface RawData {
  created_at: Date;
  google_drive_folder_id: string;
  google_drive_folder_name: string;
  id: number;
  last_updated_at: Date;
  naver_account_id: number;
  sheetfile_id: string;
  spreadsheet_id: string;
  spreadsheet_name: string;
  type: RawDataFormatType;
  use_templates: number[];
  conversion_type: string;

  _title: string;
  _thumbnail: string;
  _templates: TemplateItem[];
}

export interface _mockRawData {
  _templates: TemplateItem[];
  _raw_data_format: RawDataFormatType;
  _standard: string;
  _title: string;
  _description: string;
  _period: string;
  _thumbnail: string;
  _url: string;
  _hover: boolean;
  _conversion_type: string;
}

export interface TemplateData {
  version: number;
  data: { [key in RawDataFormatType]: TemplateItem[] };
}

export interface TemplateItem {
  id: number;
  korean_name: string;
  platform: number;
  raw_data_format_type: number;
  sheetfile_gid: string;
  sheetfile_name: string;
  spreadsheet_id: string;
  version: number;
  report_no: number;

  _checked: boolean;
}

export enum RawDataFormatType {
  NSA_GTD = 1, //		광고그룹 성과
  NSA_GT = 2, // 광고그룹 성과 (기기 X)
  NSA_KD = 3, //	기기별 키워드 성과
  NSA_K = 4, // 키워드 성과
  NSA_CTD = 5, // 캠페인 성과
  NSA_CT = 6, // 	캠페인 성과 (기기 X)
}

export enum RawDataImportStatus {
  FAILED = 0, //	실패
  CREATING = 1, //	스프레드시트 생성 중
  IMPORTING_WATING = 2, //	플랫폼에서 데이터 수집 대기 중
  IMPORTING = 3, //	플랫폼에서 데이터 수집 중
  PROCESSING = 4, //	데이터 처리 중
  INSERTING = 5, //	스프레드시트에 데이터 삽입 중
  ADDING = 6, //	템플릿 추가 중
  SUCCESS = 7, //	완료
}

export enum UpdateStatus {
  DELETED = 1, // 파일이 삭제 되었을 때
  FAILED = 2, // 업데이트에 실패 했을 때
}
