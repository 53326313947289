import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from '../services/api.service';

export const superadminGuard: CanActivateFn = () => {
  const apiService = inject(ApiService);
  const router = inject(Router);
  const service_id = localStorage.getItem(apiService.serviceIdKey);
  const allowedServiceIds = ['171y4vv5', 'avqz7dh0', 'eu7p7tde', 'ucwq2f9u', '1g3hwnch', 'zjygzhlm'];
  if (allowedServiceIds.includes(service_id || '')) {
    return true; // 접근 허용
  }

  router.navigate(['/my-data']); // 조건 불만족 시 로그인 페이지로 리다이렉트
  return false; // 접근 차단
};
