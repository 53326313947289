import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './api.service';
import { UserService } from './user.service';
import { GenericService } from '../../shared/services/generic.service';
import { TemplatesService } from '../../shared/services/templates.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  private redirectUri = `${this.apiService.baseUrl}/auth/google/callback`;

  loginLoading: boolean = false;
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private genericService: GenericService,
    private templatesService: TemplatesService,
    private router: Router,
  ) {}

  public signIn() {
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.clientId}&redirect_uri=${this.redirectUri}&access_type=offline&scope=profile email https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/spreadsheets.readonly&response_type=code&prompt=consent`;
    window.location.href = googleAuthUrl;
  }

  public handleAuthCallback(serviceId: string) {
    this.verifyTokenWithServer(serviceId);
  }

  private verifyTokenWithServer(serviceId: string) {
    this.loginLoading = true;
    const url = 'auth/google';
    this.apiService.select(url, { service_id: serviceId }).subscribe({
      next: (response) => {
        this.userService.setUser(response.body);
        localStorage.setItem(this.apiService.serviceIdKey, serviceId);

        this.userService.user && this.genericService.pushToDataLayer('sign_in', { login_id: this.userService.user.login_id });
        this.templatesService.checkAndUpdateTemplate().then(() => this.routeUser());
      },
      error: () => {
        localStorage.removeItem(this.apiService.serviceIdKey);
        this.loginLoading = false;
      },
    });
  }

  private routeUser() {
    if (this.genericService.isMobile()) {
      this.router.navigate(['/mobile']);
      return;
    }

    if (this.userService.user?.has_naver_raw_data || !this.userService.user?.is_billing) this.router.navigate(['/my-data']);
    else this.router.navigate(['/home']);
  }

  public signOut() {
    localStorage.removeItem(this.apiService.serviceIdKey);
    sessionStorage.clear();
    this.userService.user = null;
    this.loginLoading = false;

    this.router.navigate(['/login']);
  }
}
