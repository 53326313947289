<article id="report-dialog">
  <h2 class="report-title">무료 템플릿 보기<img (click)="onCancel()" src="assets/icon/close.svg" alt="close button" /></h2>

  <section class="report-scroll">
    <div class="report-info">
      <div>
        <h3>
          <img src="assets/icon/wand.svg" alt="wand icon" />'{{ rawData._title }}'
          @if (templateData.length) {
            {{ type === 'preview' ? '데이터로 연동 시, 이런 템플릿도 사용할 수 있어요.' : '데이터로 활용할 수 있는 템플릿이 있어요.' }}
          } @else {
            데이터로 활용할 수 있는 템플릿이 없어요.
          }
        </h3>
        @if (templateData.length) {
          <p>
            {{
              type === 'preview'
                ? '‘' + rawData._title + '’ 연동 후, 아래 템플릿을 원하는 만큼 선택해서 함께 연동해 보세요.'
                : '템플릿 선택 시, 데이터 뿐만 아니라 데이터가 적용된 템플릿도 함께 연동할 수 있어요.'
            }}
          </p>
        }
      </div>
      <a href="https://saladlab.notion.site/13e46f3e549c8016be76eec192e8f0d7?pvs=4" target="_blank">모든 템플릿 보기</a>
    </div>

    <div class="report-template">
      @for (template of templateData; track $index) {
        <div class="report-template-wrap" [ngClass]="{ selected: selectedTemplates.includes(template.id) }">
          <div class="report-template-box">
            <img src="assets/icon/excel_bg.svg" alt="excel sheets" />
            @if (!useTemplate.includes(template.report_no)) {
              <button
                (click)="onRouterPreview(template)"
                (mouseover)="hoverIndex = $index"
                (mouseleave)="hoverIndex = null"
                class="report-template-preview"
                [ngClass]="{ hovering: hoverIndex === $index }"
              >
                템플릿 미리보기<img src="assets/icon/external.svg" alt="external link" />
              </button>
            } @else {
              <p class="report-template-use">사용 중</p>
            }
          </div>
          <div class="report-template-checkbox" [ngClass]="{ preview: type !== 'add' }">
            <mat-checkbox [(ngModel)]="template._checked" (ngModelChange)="onCheckboxChange(template.report_no, $event)" [disabled]="useTemplate.includes(template.report_no)"
              ><span class="report-template-ellipsis">{{ template.korean_name }}</span> <img src="assets/icon/excel.svg" alt="Spreadsheet icon"
            /></mat-checkbox>
          </div>
        </div>
      }
    </div>
  </section>

  @if (userService.user?.is_billing) {
    <div class="report-footer">
      @if (!userService.user?.has_naver_manager_account) {
        <p class="report-footer-guide"><img src="assets/icon/naver.png" alt="naver icon" /> 템플릿을 연동하시려면 네이버 검색광고 계정을 연결해 주세요.</p>
        <div class="report-footer-buttons">
          <button (click)="onCancel()" class="report-footer-button white">닫기</button>
          <button (click)="onConfirm('connected')" class="report-footer-button primary">계정 연결</button>
        </div>
      } @else {
        @if (type === 'preview') {
          <p class="report-footer-guide primary">[데이터 연동하기]를 클릭해서 데이터 및 템플릿을 함께 연동해 보세요.</p>
          <div class="report-footer-buttons">
            <button (click)="onConfirm('created')" class="report-footer-button preview"><img src="assets/icon/link.svg" alt="export button" />데이터 연동하기</button>
          </div>
        } @else {
          <p class="report-footer-guide sub">
            @if (!useTemplate.length) {
              @if (hover !== null && hover) {
                <span [@hover]> [템플릿 연동] 선택 시, 로우데이터를 포함한 템플릿이 구글 드라이브에 연동돼요. </span>
              } @else if (hover !== null && !hover) {
                <span [@hover]> [데이터만 연동] 선택 시, ‘{{ rawData._title }}’ 데이터를 원하는 드라이브 폴더에 연동할 수 있어요. </span>
              }
            }
          </p>
          <div class="report-footer-buttons">
            @if (!useTemplate.length && !myData) {
              <button (click)="onConfirm('onlyData')" (mouseenter)="hover = false" class="report-footer-button gray">데이터만 연동</button>
            } @else {
              <button (click)="onCancel()" class="report-footer-button white">닫기</button>
            }
            <button
              (click)="selectedTemplates.length ? onConfirm('useTemplate') : genericService.return()"
              (mouseenter)="hover = true"
              class="report-footer-button primary"
              [ngClass]="{ disabled: !selectedTemplates.length }"
            >
              템플릿 연동
            </button>
          </div>
        }
      }
    </div>
  }
</article>
