import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { fadeInOutTrigger } from '../../animations';

import { _mockRawData, RawDataFormatType } from '../../models/raw-data.model';

import { RawDataService } from '../../services/raw-data.service';
import { UserService } from '../../../core/services/user.service';
import { NaverAccountService } from '../../services/naver-account.service';
import { GenericService } from '../../services/generic.service';

@Component({
  selector: 'app-sample-raw-data',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './raw-data-template.component.html',
  styleUrl: './raw-data-template.component.less',
  animations: [fadeInOutTrigger('contentHover', '0.3s ease-in-out')],
})
export class RawDataTemplateComponent {
  @Input() addData!: boolean;
  @Input() activeRawData?: RawDataFormatType[];
  constructor(
    public userService: UserService,
    public rawDataService: RawDataService,
    private naverAccountService: NaverAccountService,
    private genericService: GenericService,
  ) {}

  ngOnInit(): void {}

  onClickButton(rawData: _mockRawData) {
    if (!this.userService.user?.has_naver_manager_account) this.naverAccountService.getConnectedManagerAccount();
    else this.rawDataService.startGoogleDriveDialog(rawData);
  }

  onRouterPreview(rawData: _mockRawData) {
    window.open(rawData._url);
    this.genericService.pushToDataLayer('preview_raw', {
      login_id: this.userService.user?.login_id,
      media_account_id: this.naverAccountService.selectAccount.naver_login_id,
      media: 'naver sa',
      raw_name: rawData._raw_data_format,
      page_name: this.genericService.currentPath,
    });
  }
}
