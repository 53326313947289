import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { MyRawData, RawDataFormat, RawDataFormatType, UpdateStatus, _mockRawData } from '../models/raw-data.model';
import { Platform } from '../models/accounts.model';
import { GoogleDriveItem } from '../models/google-drive.model';

import { NaverAccountService } from './naver-account.service';
import { ApiService } from '../../core/services/api.service';
import { UserService } from '../../core/services/user.service';
import { GenericService } from './generic.service';
import { TemplatesService } from './templates.service';

import { StartGoogleDriveDialogComponent } from '../components/dialogs/start-google-drive-dialog/start-google-drive-dialog.component';
import { ReportDialogComponent } from '../components/dialogs/report-dialog/report-dialog.component';
// import { GoogleDriveDirectoryDialogComponent } from '../components/dialogs/google-drive-directory-dialog/google-drive-directory-dialog.component';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { GooglePickerService } from './google-picker.service';

@Injectable({
  providedIn: 'root',
})
export class RawDataService {
  sampleRawData: _mockRawData[] = [];
  selectedRawData?: _mockRawData;
  useTemplates: string[] = [];
  formatOnData: RawDataFormat = new RawDataFormat();
  rawDataArray = Object.values(RawDataFormatType).filter((value) => typeof value === 'number') as RawDataFormatType[];

  templates: any;
  private readonly rawDataFormatTitle: { [key in RawDataFormatType]: string } = {
    [RawDataFormatType.NSA_GTD]: '기기별 광고 그룹 성과',
    [RawDataFormatType.NSA_GT]: '광고 그룹 성과',
    [RawDataFormatType.NSA_KD]: '기기별 키워드 성과',
    [RawDataFormatType.NSA_K]: '키워드 성과',
    [RawDataFormatType.NSA_CTD]: '기기별 캠페인 성과',
    [RawDataFormatType.NSA_CT]: '캠페인 성과',
  };
  private readonly rawDataFormatUrl: { [key in RawDataFormatType]: string } = {
    [RawDataFormatType.NSA_GTD]: 'https://docs.google.com/spreadsheets/d/1wIQPtvhbFj8uC-DhzlTxLD1GJNTuzkbBGVspFLsqr50/edit?gid=1040378331#gid=1040378331',
    [RawDataFormatType.NSA_GT]: 'https://docs.google.com/spreadsheets/d/1ZxYvYwUFaXQ1-4CLqer4sjjNrcX9h8oUCZ53SRwSvqI/edit?gid=560617457#gid=560617457&range=A1',
    [RawDataFormatType.NSA_KD]: 'https://docs.google.com/spreadsheets/d/1ogRL1nwLF9LKK9CemGjuHbM6jA6ZM-Lxer_O4rYiQj0/edit?gid=1378420137#gid=1378420137',
    [RawDataFormatType.NSA_K]: 'https://docs.google.com/spreadsheets/d/1PyDlk0PbC3JCt0q2PA--PJ3qj08w597BCp8wd75WDKw/edit?gid=2011288991#gid=2011288991',
    [RawDataFormatType.NSA_CTD]: 'https://docs.google.com/spreadsheets/d/1cfDe9TqzbDkAR-tV3Ezt3O8X5JeDeXXlvUAynFPg2mU/edit?gid=1368654713#gid=1368654713',
    [RawDataFormatType.NSA_CT]: 'https://docs.google.com/spreadsheets/d/1GkrvMeVk3kum6fH3Rrm061OvL-fOFZ8e60C0pwaQj8g/edit?gid=68385790#gid=68385790',
  };
  private readonly rawDataFormatThumbnail: { [key in RawDataFormatType]: string } = {
    [RawDataFormatType.NSA_GTD]: 'assets/images/NSA_GTD.png',
    [RawDataFormatType.NSA_GT]: 'assets/images/NSA_GT.png',
    [RawDataFormatType.NSA_KD]: 'assets/images/NSA_KD.png',
    [RawDataFormatType.NSA_K]: 'assets/images/NSA_K.png',
    [RawDataFormatType.NSA_CTD]: 'assets/images/NSA_CTD.png',
    [RawDataFormatType.NSA_CT]: 'assets/images/NSA_CT.png',
  };
  private readonly standards: string[] = [
    'assets/images/NSA_GTD_standard.png',
    'assets/images/NSA_GT_standard.png',
    'assets/images/NSA_KD_standard.png',
    'assets/images/NSA_K_standard.png',
    'assets/images/NSA_CTD_standard.png',
    'assets/images/NSA_CT_standard.png',
  ];
  private readonly descriptions: string[] = [
    `네이버 검색광고 분석 메인 RAW 데이터 리포트`,
    `기기 구분이 제외된 세부 유형별 성과`,
    `키워드별 성과 파악`,
    `기기 구분이 제외된 설정한 키워드별 성과 파악`,
    `캠페인 유형과 캠페인 명칭에 따른 세부 성과 파악`,
    `기기 구분이 제외된 캠페인 유형과 캠페인 명칭에 따른 세부 성과 파악`,
  ];
  conversionType: Record<string, string> = {
    purchase: '구매완료',
    sign_up: '회원 가입',
    add_to_cart: '장바구니 담기',
    lead: '신청 완료',
    add_to_wishlist: '위시리스트 추가',
    subscribe: '소식받기/구독',
    schedule: '예약 완료',
    view_content: '콘텐츠 보기',
  };
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private naverAccountService: NaverAccountService,
    private genericService: GenericService,
    private templatesService: TemplatesService,
    private router: Router,
    private dialog: MatDialog,
    private googlePickerService: GooglePickerService,
  ) {
    this.loadTemplates().then(() => this._mockRawData());
  }

  getDescriptionOrThumbnail(format: RawDataFormatType, type: string): string {
    return type === 'title' ? this.rawDataFormatTitle[format] : this.rawDataFormatThumbnail[format];
  }

  async loadTemplates(): Promise<void> {
    this.templates = await this.templatesService.getTemplateData();
  }

  _mockRawData(): void {
    this.sampleRawData = this.rawDataArray.map((format, index) => {
      const period = [RawDataFormatType.NSA_KD, RawDataFormatType.NSA_K].includes(format) ? '최근 3개월간 / 최대 15만행' : '최근 1년간 / 최대 15만행';
      return {
        _templates: this.templates[format],
        _conversion_type: '',
        _raw_data_format: format,
        _thumbnail: this.getDescriptionOrThumbnail(format, 'thumbnail'),
        _standard: this.standards[index % this.standards.length],
        _title: this.getDescriptionOrThumbnail(format, 'title'),
        _description: this.descriptions[index % this.standards.length],
        _period: period,
        _url: this.rawDataFormatUrl[format],
        _hover: false,
      };
    });
  }

  openDialogReport(rawData: _mockRawData, type: string) {
    const dialogRef = this.dialog.open(ReportDialogComponent, { width: '800px', maxHeight: '80vh', data: { rawData: null, mockRawData: rawData, type: type } });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.formatOnData.folder_id = undefined;
        this.formatOnData.folder_name = undefined;
        this.formatOnData.spreadsheet_id = undefined;
        this.formatOnData.spreadsheet_name = undefined;
        return;
      }

      switch (result.message) {
        case 'connected':
          this.naverAccountService.getConnectedManagerAccount();
          break;
        case 'created':
          this.startGoogleDriveDialog(rawData);
          break;
        case 'useTemplate':
          this.formatOnData = this.formattingRawData(this.formatOnData, null, null, result.templates);
          break;
        case 'onlyData':
          this.formatOnData = this.formattingRawData(this.formatOnData, null, null, []);
      }
      if (type === 'add') this.addRawData();
    });
  }

  startGoogleDriveDialog(rawData: _mockRawData) {
    const dialogRef = this.dialog.open(StartGoogleDriveDialogComponent, { width: '460px', data: rawData });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.selectedRawData!._conversion_type = result.conversionType;
      if (result.type === 'save') {
        this.formatOnData = this.formattingRawData(this.formatOnData, null, null, []);
        rawData._templates.length ? this.openDialogReport(rawData, 'add') : this.addRawData();
      } else this.openSelectedRoot(result.type);
    });
  }

  openSelectedRoot(type: 'sheets' | 'directory') {
    this.googlePickerService.openPicker((data: any) => {
      if (data.action === google.picker.Action.PICKED) {
        // 선택된 파일 데이터 처리
        const selectedFile = data.docs[0];
        this.updateFormatOnData(type, selectedFile);
        this.selectedRawData!._templates.length ? this.openDialogReport(this.selectedRawData!, 'add') : this.addRawData();
      }
    }, type);
    // const dialogRef = this.dialog.open(GoogleDriveDirectoryDialogComponent, { width: '800px', maxHeight: '80vh', data: { type: type } });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (!result) return;
    //   this.updateFormatOnData(type, result);
    //   this.selectedRawData!._templates.length ? this.openDialogReport(this.selectedRawData!, 'add') : this.addRawData();
    // });
  }

  formattingRawData(existingData: RawDataFormat, folder: GoogleDriveItem | null, sheets?: GoogleDriveItem | null, template?: string[]): RawDataFormat {
    return {
      platform: Platform.NAVER,
      naver_account_id: this.naverAccountService.selectAccount.id,
      conversion_type: this.selectedRawData!._conversion_type,
      type: this.selectedRawData!._raw_data_format,
      template: template?.length ? template : existingData.template,
      folder_id: folder ? folder.id : existingData.folder_id,
      folder_name: folder ? folder.name : existingData.folder_name,
      spreadsheet_id: sheets ? sheets.id : existingData.spreadsheet_id,
      spreadsheet_name: sheets ? sheets.name : existingData.spreadsheet_name,
    };
  }

  updateFormatOnData(type: 'sheets' | 'directory', result: any) {
    this.formatOnData = this.formattingRawData(this.formatOnData, type === 'directory' ? result : null, type === 'sheets' ? result : null);
  }

  addRawData() {
    const url = 'raw-data-format';
    this.apiService.create(url, this.formatOnData).subscribe((response) => {
      const body = response.body as boolean;
      if (!body) return;
      this.pushToDataLayer(this.formatOnData);
      this.confirmAddRawDataInfo();
    });
  }

  confirmAddRawDataInfo() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '460px', disableClose: true });
    const dialogInstance = dialogRef.componentInstance;
    dialogInstance.title = `데이터 연동 시작!`;
    dialogInstance.confirmText = '확인';
    dialogInstance.confirmButtonBgColor = `#837aff`;
    dialogInstance.onlyConfirm = true;
    dialogInstance.email = this.userService.user?.login_id!;

    dialogRef.afterClosed().subscribe(() => {
      this.userService.getUser(() => this.router.navigate(['my-data']));
      this.formatOnData = new RawDataFormat();
    });
  }

  pushToDataLayer(rawData: RawDataFormat) {
    this.genericService.pushToDataLayer('dashboard_insert_raw', {
      login_id: this.userService.user?.login_id,
      media_account_id: this.naverAccountService.selectAccount.naver_login_id,
      media: 'naver sa',
      raw_name: rawData.type,
      page_name: this.genericService.currentPath,
    });

    if (rawData.template.length) {
      rawData.template.forEach((item) => {
        this.genericService.pushToDataLayer('dashboard_insert_report', {
          login_id: this.userService.user?.login_id,
          media_account_id: this.naverAccountService.selectAccount.naver_login_id,
          media: 'naver sa',
          report_name: this.templates[rawData.type].find((t: any) => t.report_no === item)?.sheetfile_name,
          page_name: this.genericService.currentPath,
        });
      });
    }
  }

  getRawData() {
    const url = `raw-data-format`;
    return this.apiService.select(url, { platform: Platform.NAVER, naver_account_id: this.naverAccountService.selectAccount.id });
  }

  checkRawDataLogStatus(rawDataId: number) {
    const url = `raw-data-format/status`;
    return this.apiService.select(url, { platform: Platform.NAVER, raw_data_id: rawDataId });
  }

  firstRetryRawData(rawDataId: number) {
    const url = `raw-data-format/retry/first`;
    return this.apiService.create(url, {}, { platform: Platform.NAVER, raw_data_id: rawDataId });
  }

  updateRetryRawData(rawData: MyRawData) {
    const url = `raw-data-format/retry/update`;
    return this.apiService.create(url, {}, { reason: rawData.update_status_reason, platform: Platform.NAVER, raw_data_id: rawData.raw_data.id });
  }

  updateRawData(rawDataId: number) {
    const url = `raw-data-format/update`;
    return this.apiService.create(url, {}, { platform: Platform.NAVER, raw_data_id: rawDataId });
  }

  handleRawDataAction(action: string, rawData: MyRawData) {
    const url = `raw-data-format/${action}`;
    let params: Params = {
      platform: Platform.NAVER,
      raw_data_id: rawData.raw_data.id,
    };
    if (action === 'retry/update') params = { ...params, reason: rawData.update_status_reason! };
    return this.apiService.create(url, {}, params);
  }

  deletedRawData(rawDataId: number) {
    const url = `raw-data-format`;
    return this.apiService.delete(url, { platform: Platform.NAVER, raw_data_id: rawDataId });
  }

  addRawDataTemplate(rawDataId: number, templates: number[]) {
    const url = `raw-data-format/template`;
    return this.apiService.create(url, templates, { platform: Platform.NAVER, raw_data_id: rawDataId });
  }
}
interface Params {
  platform: Platform;
  raw_data_id: number;
  reason?: UpdateStatus;
}
