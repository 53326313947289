<div calss="super-admin-container">
  <div class="super-admin-gnb">
    <div routerLink="/super-admin" class="super-admin-gnb-button">사용자 정보</div>
    <div routerLink="/super-admin/template" class="super-admin-gnb-button">템플릿 관리</div>
    <div routerLink="/my-data" class="super-admin-gnb-button">나가기</div>
  </div>
  <div class="template-admin-gnb">
    <div class="version">템플릿 버전 : {{ version }}</div>

    <div class="template-form">
      <label for="raw-data-format">로우데이터 타입:</label>
      <select id="raw-data-format" [(ngModel)]="selectedFormat">
        <option value="1">기기별 광고그룹 성과</option>
        <option value="2">광고그룹 성과</option>
        <option value="3">기기별 키워드 성과</option>
        <option value="4">키워드 성과</option>
        <option value="5">기기별 캠페인 성과</option>
        <option value="6">캠페인 성과</option>
      </select>

      <label for="url-input">URL:</label>
      <input type="text" id="url-input" [(ngModel)]="url" placeholder="URL을 입력하세요" />

      <label for="exclude-gid">제외 할 시트파일 ID:</label>
      <input type="text" id="exclude-gid" [(ngModel)]="excludeGidInput" placeholder="콤마로 구분하여 제외할 Gid(시트파일 ID)를 입력하세요" (change)="updateExcludeGid()" />

      <div class="button-group">
        <button class="notification-button" (click)="getNotification()">주의사항보기</button>
        <button class="test-button" (click)="testCreateTemplates()">테스트</button>
        <button class="create-button" (click)="createTemplates()">만들기</button>
        <button class="delete-button" (click)="deleteresponseMessage()">내역 지우기</button>
        <button class="update-button" (click)="updateReportNo()">리포트 번호 최신화</button>
        <button class="validate-button" (click)="validateReportNo()">리포트 번호 검사</button>
      </div>
    </div>
    @if (responseMessage) {
      <div class="response-box">
        <p>{{ responseMessage }}</p>
      </div>
    }
  </div>
  <section id="template-table" class="template-table-section">
    <table class="table">
      <!-- 테이블 헤더 -->
      <thead class="table-header">
        <tr>
          <th>템플릿 넘버</th>
          <th>로우데이터 타입</th>
          <th>템플릿 이름</th>
          <th>시트 제목</th>
          <th>스프레드시트 ID</th>
          <th>시트파일 ID</th>
          <th>지우기</th>
        </tr>
      </thead>

      <!-- 테이블 바디 -->
      <tbody class="table-body">
        @for (template of templates; track $index) {
          <tr>
            <td>{{ template.report_no }}</td>
            <td>{{ template.raw_data_format_type }}</td>
            <td>{{ template.korean_name }}</td>
            <td>{{ template.sheetfile_name }}</td>
            <td>{{ template.spreadsheet_id }}</td>
            <td>{{ template.sheetfile_gid }}</td>
            <td><button class="delete-button" (click)="deleteTemplate(template.report_no)">삭제</button></td>
          </tr>
        }
      </tbody>
    </table>
  </section>
</div>
