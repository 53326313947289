<div calss="super-admin-container">
  <div class="super-admin-gnb">
    <div routerLink="super-admin" class="super-admin-gnb-button">사용자 정보</div>
    <div routerLink="/super-admin/template" class="super-admin-gnb-button">템플릿 관리</div>
    <div routerLink="/my-data" class="super-admin-gnb-button">나가기</div>
  </div>
  <section id="user-table" class="user-table-section">
    <table class="table">
      <!-- 테이블 헤더 -->
      <thead class="table-header">
        <tr>
          <th>회사 ID</th>
          <th>회사 이름</th>
          <th>회사 사업자 번호</th>
          <th>유저 ID</th>
          <th>구글 로그인 ID</th>
          <th>가입일</th>
          <th>첫 로우데이터 처리 일</th>
          <th>마지막 로우데이터 처리 일</th>
          <th>결제 상태</th>
          <th>로우데이터 수</th>
          <th>로우데이터 상태</th>
          <th>템플릿 수</th>
          <th>메모1</th>
          <th>메모2</th>
          <th>메모3</th>
          <th>어드민 URL</th>
          <th>저장</th>
        </tr>
      </thead>

      <!-- 테이블 바디 -->
      <tbody class="table-body">
        @for (user of users; track $index) {
          <tr>
            <td>{{ user.company_id }}</td>
            <td>
              <input type="text" [placeholder]="user.company_name" [(ngModel)]="user.company_name" />
            </td>
            <td>
              <input type="text" [placeholder]="user.company_business_number" [(ngModel)]="user.company_business_number" />
            </td>
            <td>{{ user.user_id }}</td>
            <td>{{ user.google_login_id }}</td>
            <td>{{ user.sign_up_date }}</td>
            <td>{{ user.first_raw_data_import_date }}</td>
            <td>{{ user.last_raw_data_import_date }}</td>
            <td>
              <img class="billing-toggle" [src]="user.is_billing ? 'assets/icon/toggle_on.svg' : 'assets/icon/toggle_off.svg'" alt="토글 버튼" (click)="toggleBilling(user)" />
            </td>
            <td>{{ user.raw_count }}</td>
            <td>{{ user.raw_status }}</td>
            <td>{{ user.template_count }}</td>
            <td>
              <textarea class="memo-text-area" [placeholder]="user.memo_one" [(ngModel)]="user.memo_one"></textarea>
            </td>
            <td>
              <textarea class="memo-text-area" [placeholder]="user.memo_two" [(ngModel)]="user.memo_two"></textarea>
            </td>
            <td>
              <textarea class="memo-text-area" [placeholder]="user.memo_three" [(ngModel)]="user.memo_three"></textarea>
            </td>
            <td>
              <a [href]="user.admin_url" target="_blank">접속</a>
            </td>
            <td>
              <button (click)="save(user)">저장</button>
            </td>
          </tr>
        }
      </tbody>
    </table>

    <!-- 데이터가 없을 경우 -->
    <ng-template #noUsers>
      <div class="no-data-message">등록된 사용자가 없습니다.</div>
    </ng-template>
  </section>
</div>
